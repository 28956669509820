'use client';

import { Button } from '@nextui-org/button';
import { Link } from '@nextui-org/link';
import {
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Navbar as NextUINavbar,
  NavbarProps as NextUINavbarProps,
} from '@nextui-org/navbar';
import { cn } from '@nextui-org/theme';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { Divider } from '@nextui-org/divider';
import { useMemo, useState } from 'react';
import { Tooltip } from '@nextui-org/tooltip';
import { Chip } from '@nextui-org/chip';
import { useSession } from 'next-auth/react';
import { Bell, Plus } from '@phosphor-icons/react/dist/ssr';

import ProfileDropdown from './ProfileDropdown';

import { ArrowRightSvg } from '@/assets/icons';
import { LogoSvg } from '@/assets/svgs';
import { SITES } from '@/config/site';
import { body } from '@/theme/typography';

interface HeaderProps extends NextUINavbarProps {
  variant?: 'light' | 'dark';
}

const Header: React.FC<HeaderProps> = ({ variant = 'light', className, ...others }) => {
  const { data: session } = useSession();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathname = usePathname();

  const handleMenuOpenChange = (open: boolean) => {
    setIsMenuOpen(open);
  };

  const handleClick = () => {
    setIsMenuOpen(false);
  };

  const headerTheme = useMemo(() => (isMenuOpen ? 'light' : variant), [isMenuOpen, variant]);

  return (
    <NextUINavbar
      className={cn(
        'z-50 transition-background',
        {
          'bg-blue-950 text-neutral-white': headerTheme === 'dark',
          'bg-neutral-white text-neutral-black': headerTheme === 'light',
        },
        className,
      )}
      height='96px'
      maxWidth='xl'
      position='sticky'
      {...others}
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={handleMenuOpenChange}
    >
      <NavbarContent className='basis-1/5 sm:basis-full' justify='start'>
        <NavbarBrand as='li' className='max-w-fit gap-3'>
          <NextLink className='flex items-center justify-start gap-3' href='/'>
            <LogoSvg height={40} width={54} />

            <div className='flex flex-col leading-5'>
              <p className='font-bold'>Bimmer Head</p>
              <p>Vietnam</p>
            </div>
          </NextLink>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent
        className={cn('ml-2 hidden !justify-end gap-6 lg:flex xl:gap-12', {
          'text-neutral-white': headerTheme === 'dark',
          'text-neutral-black': headerTheme === 'light',
        })}
      >
        {SITES.items.map(({ href, label, isPublished }) => (
          <NavbarItem key={href} className='group h-full'>
            {!isPublished && (
              <Tooltip
                classNames={{
                  content: cn(
                    'bg-gradient-to-l from-bmw-blue-300 via-bmw-blue-500 to-bmw-red-500',
                    'text-neutral-white px-4 py-2',
                  ),
                }}
                content='Coming soon'
                offset={-16}
                placement='bottom'
                radius='full'
              >
                <span className='flex h-full cursor-default items-center'>{label}</span>
              </Tooltip>
            )}
            {isPublished && (
              <NextLink
                className={cn(
                  body({ weight: pathname.includes(href) ? 'bold' : 'regular' }),
                  'relative flex h-full items-center transition-all after:transition-all after:duration-200',
                  'after:h-0 after:opacity-0',
                  'data-[active=true]:after:h-1 data-[active=true]:after:opacity-100 hover:after:h-1 hover:after:opacity-100',
                  'data-[active=true]:hover:after:left-0 data-[active=true]:hover:after:right-0',
                  'after:absolute after:bottom-6 after:left-1 after:right-1 after:content-[""]',
                  'after:bg-gradient-to-r after:from-bmw-blue-300 after:via-bmw-blue-500 after:to-bmw-red-500',
                )}
                data-active={pathname.includes(href)}
                href={href}
              >
                {label}
              </NextLink>
            )}
          </NavbarItem>
        ))}

        <NavbarItem>
          {!session?.user && (
            <Link href='/auth/sign-in'>
              <Button
                className={cn('font-bold', {
                  'bg-neutral-white text-neutral-black': headerTheme === 'dark',
                  'bg-neutral-black text-neutral-white': headerTheme === 'light',
                })}
                color='default'
                endContent={<ArrowRightSvg className='w-5' />}
                radius='none'
                size='lg'
                style={{ clipPath: 'polygon(0 20px, 20px 0, 100% 0, 100% 100%, 0 100%)' }}
              >
                Join Our Community
              </Button>
            </Link>
          )}

          {session?.user && (
            <div className='flex flex-row items-center gap-2'>
              <Button
                className={cn('border-gray-50', {
                  'bg-neutral-white text-neutral-black': headerTheme === 'dark',
                })}
                radius='none'
                size='sm'
                startContent={<Plus size={20} />}
                variant='bordered'
              >
                Create
              </Button>

              <Button
                isIconOnly
                className={cn('bg-transparent hover:bg-transparent', {
                  'text-neutral-white': headerTheme === 'dark',
                })}
                variant='light'
              >
                <Bell size={24} />
              </Button>

              <ProfileDropdown user={session.user} />
            </div>
          )}
        </NavbarItem>
      </NavbarContent>

      <NavbarContent className='flex basis-1 pl-4 lg:hidden' justify='end'>
        {session?.user && <ProfileDropdown user={session.user} />}
        <NavbarMenuToggle />
      </NavbarContent>

      <NavbarMenu
        className={cn({
          'bg-blue-950 text-neutral-white': headerTheme === 'dark',
          'bg-neutral-white text-neutral-black': headerTheme === 'light',
        })}
      >
        <div className='flex flex-col'>
          {SITES.items.map(({ href, label, isPublished }, index) => (
            <NavbarMenuItem key={`${href}-${index}`} onClick={handleClick}>
              <Link
                as={NextLink}
                className={cn(
                  body({ weight: 'medium' }),
                  'flex w-full flex-row items-center gap-4 py-4 text-inherit transition-all',
                  {
                    'text-neutral-black': pathname.includes(href),
                    'text-gray-600': !pathname.includes(href),
                  },
                )}
                href={href}
                size='lg'
              >
                <span>{label}</span>

                {!isPublished && (
                  <Chip
                    className={cn(
                      'bg-gradient-to-l from-bmw-blue-300 via-bmw-blue-500 to-bmw-red-500',
                      'text-neutral-white',
                    )}
                    content='Coming soon'
                    radius='full'
                    size='sm'
                  >
                    <span className='cursor-not-allowed'>Coming soon</span>
                  </Chip>
                )}
              </Link>

              {index !== SITES.items.length - 1 && (
                <Divider
                  className={cn({ 'bg-gray-800': headerTheme === 'dark' })}
                  orientation='horizontal'
                />
              )}
            </NavbarMenuItem>
          ))}

          {!session?.user && (
            <NavbarItem className='mt-4'>
              <Link href='/auth/sign-in'>
                <Button
                  fullWidth
                  className={cn('font-bold', {
                    'bg-neutral-white text-neutral-black': headerTheme === 'dark',
                    'bg-neutral-black text-neutral-white': headerTheme === 'light',
                  })}
                  color='default'
                  endContent={<ArrowRightSvg className='w-5' />}
                  radius='none'
                  size='lg'
                  style={{ clipPath: 'polygon(0 20px, 20px 0, 100% 0, 100% 100%, 0 100%)' }}
                >
                  Join Our Community
                </Button>
              </Link>
            </NavbarItem>
          )}
        </div>
      </NavbarMenu>
    </NextUINavbar>
  );
};

export default Header;
