export type SiteConfig = typeof SITES;

export const SITES = {
  name: 'Bimmer Head Vietnam',
  description: 'Bimmer Head Vietnam is a community for BMW enthusiasts in Vietnam.',
  items: [
    { label: 'News', href: '/news', isPublished: true },
    { label: 'Guide', href: '/guides', isPublished: true },
    { label: 'Review', href: '/reviews', isPublished: true },
    { label: 'E-Magazine', href: '/e-magazines', isPublished: true },
    { label: 'Models', href: '/models', isPublished: false },
    { label: 'Gallery', href: '/gallery', isPublished: false },
    { label: 'Store', href: '/store', isPublished: false },
  ],
};
