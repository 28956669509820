import { Avatar } from '@nextui-org/avatar';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import * as React from 'react';
import { signOut } from 'next-auth/react';
import { User as NextAuthUser } from 'next-auth';
import { BookmarkSimple, CaretDown, Gear, SignOut, User } from '@phosphor-icons/react/dist/ssr';
import { redirect } from 'next/navigation';
import { cn } from '@nextui-org/theme';

import { body } from '@/theme/typography';

interface ProfileDropdownProps {
  user: NextAuthUser;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ user }) => (
  <Dropdown
    showArrow
    classNames={{
      base: 'before:bg-neutral-white', // Change arrow background
      content: 'p-0 border-none bg-background rounded-none',
    }}
    offset={20}
    placement='bottom-end'
    radius='sm'
    size='lg'
  >
    <DropdownTrigger>
      <div className='flex flex-row items-center gap-2 text-inherit'>
        <Avatar
          as='button'
          className='transition-transform'
          size='sm'
          src={user.avatar?.url ?? ''}
        />
        <CaretDown className='hidden w-5 lg:block' />
      </div>
    </DropdownTrigger>

    <DropdownMenu aria-label='Profile Menu' className='p-3' variant='light'>
      <DropdownItem
        key='profile'
        showDivider
        className={cn(body(), 'after:bg-gray-50')}
        href={`/${user.username}`}
        startContent={<User size={24} />}
        textValue='Saved'
      >
        Profile
      </DropdownItem>

      <DropdownItem
        key='saved'
        showDivider
        className={cn(body(), 'after:bg-gray-50')}
        href='/saved'
        startContent={<BookmarkSimple size={24} />}
        textValue='Saved'
      >
        Saved
      </DropdownItem>

      <DropdownItem
        key='settings'
        showDivider
        className={cn(body(), 'after:bg-gray-50')}
        startContent={<Gear size={24} />}
        textValue='Settings'
      >
        Settings
      </DropdownItem>

      <DropdownItem
        key='logout'
        className={cn(body(), 'text-danger')}
        color='danger'
        startContent={<SignOut size={24} />}
        textValue='Sign out'
        onClick={() => {
          signOut({ redirect: false });
          redirect('/');
        }}
      >
        Sign Out
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
);

export default ProfileDropdown;
